.combobox-container {
  @apply relative flex min-h-[36px] w-full cursor-default appearance-none flex-row justify-between overflow-hidden rounded border border-tertiary-500 bg-white text-base leading-8 text-tertiary-100 transition-colors duration-200 ease-in-out dark:bg-quaternary-200 dark:text-white dark:placeholder:text-white;
  @apply disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;

  &:has(input:focus) {
    @apply border-primary-blue-200 ring ring-primary-blue-900;
  }
}

.error-combobox {
  @apply rounded border-1 border-red-500 ring ring-red-100;
}

.combobox-input {
  @apply flex min-h-[36px] w-auto appearance-none flex-row justify-between rounded border border-none border-tertiary-500 bg-white pl-3 font-content text-base text-sm font-light leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out placeholder:text-tertiary-400 dark:border-white dark:bg-quaternary-200 dark:text-white dark:ring-0 dark:placeholder:text-tertiary-200;
  @apply disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;

  background-color: white !important;

  :disabled {
    background-color: theme('colors.tertiary.800') !important;
  }
}
.checkbox-button {
  @apply flex h-4 w-4 cursor-pointer
  appearance-none items-center gap-4
  rounded-lg border
  bg-contain bg-center bg-no-repeat
  align-top shadow transition 
  duration-200 hover:border-primary-green-100
  focus:ring-2 focus:ring-tertiary-500;
}
.error-input-checkbox {
  @apply rounded border-1 border-red-500 ring ring-red-100;
}
.container-checkbox-button {
  @apply my-auto flex cursor-pointer items-center;
}
.checkbox-active {
  @apply justify-center border-primary-green-100 bg-primary-green-100;
}
.checkbox-inactive {
  @apply border-tertiary-500 bg-white;
}
.disabled-active {
  @apply border-blue-100 bg-tertiary-400;
}
.check-icon {
  @apply mx-auto mt-1;
}
.input-checkbox {
  @apply float-left mr-2 h-4 w-4 cursor-pointer appearance-none
  rounded border border-blue-100 bg-white
  bg-contain 
  bg-center bg-no-repeat
  pl-0.5 pr-1 align-top shadow
  transition duration-200
  focus:ring-2 focus:ring-tertiary-500;
}

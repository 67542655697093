.daterange-container {
  // Container
  & > div > :nth-child(2) {
    @apply rounded-[8px] px-0;

    // Shortcuts
    & > :first-child {
      @apply pt-1;
    }

    // Shortcuts
    & > :first-child > div:first-child > ul {
      & li {
        @apply ml-1;
      }
    }

    // Buttons on Footer
    & > :nth-child(2) > div {
      @apply pr-3;
    }

    // Month and Year
    & > div > :nth-child(2) > div > div > :first-child {
      @apply border-none;

      & > div {
        @apply text-base font-semibold text-black;
      }
    }

    // Weekdays
    & > div > :nth-child(2) > div > div > :nth-child(2) > :first-child {
      @apply border-none;

      & > div {
        @apply font-light;
      }
    }
  }
}

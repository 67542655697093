.dropzone {
  background: linear-gradient(90deg, theme('colors.primary.blue.200') 50%, transparent 50%),
    linear-gradient(90deg, theme('colors.primary.blue.200') 50%, transparent 50%),
    linear-gradient(0deg, theme('colors.primary.blue.200') 50%, transparent 50%),
    linear-gradient(0deg, theme('colors.primary.blue.200') 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 6px 1px, 6px 1px, 1px 6px, 1px 6px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  animation: dash 15s linear infinite;
  border-radius: 5px;
  padding: 10px;
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

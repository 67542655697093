.no-arrows {

  /* Remove arrows in Chrome, Safari, Edge, and Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  /* Remove arrows in Firefox */
  & {
    @apply appearance-none;
  }
}

.input-primary {
  @apply min-h-[36px] w-full rounded border-1 border-tertiary-600 bg-white pl-3 font-content text-sm leading-5 font-light text-gray-700 caret-primary-blue-200 outline-none transition-colors duration-200 ease-in-out selection:bg-primary-blue-700 selection:font-extralight placeholder:font-light placeholder:text-tertiary-400 focus:border-primary-blue-200 focus:ring focus:ring-primary-blue-900 disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 dark:border-white dark:bg-quaternary-200 dark:text-white dark:ring-0 dark:placeholder:text-tertiary-200 dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;

  &:has(input:focus) {
    @apply border-primary-blue-200 ring ring-primary-blue-900;
  }

  & input {
    @apply font-content text-sm font-light leading-8 text-gray-700 caret-primary-blue-200 outline-none placeholder:text-tertiary-400 placeholder:font-light;
  }
}

.error-input {
  @apply rounded border-1 border-red-500 ring ring-red-100;
}

.option-list {
  @apply absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm;
}

.text-color {
  @apply text-tertiary-100 dark:text-white;
}

.input-code {
  @apply input-primary;
  @apply mr-4 flex h-36 w-24 text-center text-8xl;
}

.border-pressed {
  @apply flex items-center justify-center rounded-full border-2 border-solid border-green-500 p-2;
}

.input-checkbox {
  @apply float-left mr-2 h-4 w-4 cursor-pointer appearance-none rounded border border-blue-100 bg-white bg-contain bg-center bg-no-repeat pl-0.5 pr-1 align-top shadow transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-tertiary-500;
}

.select--small {
  @apply h-6 text-xs leading-5;
}

.select--regular {
  @apply text-sm leading-8;
}

.container-input {
  @apply relative w-full;
}

.input-icon {
  @apply absolute right-[10px] top-[10px] text-xl;
}

.input-icon-start {
  @apply absolute left-[10px] top-[8px] text-xl;
}

.input-icon-close {
  @apply absolute inset-y-3 right-12 top-[13px] h-0 w-0;

  & span {
    @apply w-full cursor-pointer transition-all duration-150 ease-in-out;
  }

  & svg {
    @apply scale-[0.7];
  }

  &:not(&:has(.filter-input)):hover {
    & svg path {
      @apply fill-black;
    }
  }
}

.input-icon-eye {
  @apply absolute right-[10px] top-[6px] text-xl;

  & span {
    @apply w-full cursor-pointer transition-all duration-150 ease-in-out;
  }

  &:not(&:has(.filter-input)):hover {
    @apply rounded-full bg-tertiary-900;
  }
}
.tolq-badge {
  @apply flex flex-row gap-2 decoration-transparent;

  &>button {
    @apply hover:opacity-[0.90];
  }

  &>button.badge--blue {
    @apply bg-status-blue;
  }

  &>button.badge--red {
    @apply bg-status-red;
  }

  &>button.badge--cyan {
    @apply bg-status-cyan;
  }

  &>button.badge--green {
    @apply bg-status-green;
  }

  &>button.badge--yellow {
    @apply bg-status-yellow;
  }

  &>button.badge--gray {
    @apply bg-status-gray;
  }

  &>button.badge--grayDark {
    @apply bg-status-grayDark;
  }
}

.tolq-badge-transparent {
  @apply transition-all duration-75 ease-in-out;

  &:not(:hover)>button {
    background: transparent !important;

    * {
      @apply text-tertiary-100;
    }
  }
}
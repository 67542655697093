.pop-messages-toast .Toastify__toast-icon {
  @apply hidden;
}

.pop-messages-toast .Toastify__toast-body {
  padding: 6px !important;
  cursor: none !important;
}

.pop-messages-toast .Toastify__close-button {
  position: absolute;
  right: 0.5rem;
}

.Toastify__toast-container--bottom-right {
  @apply bottom-0 right-4 w-[432px] overflow-y-auto p-4;
  max-height: calc(100vh);

  & .pop-messages-toast:last-child {
    @apply mb-[78px];
  }
}

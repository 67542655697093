.select-tags-button {
  @apply flex min-h-[36px] w-full appearance-none flex-row items-center justify-between rounded border border-tertiary-500 bg-white px-3 text-base leading-8 text-tertiary-100 outline-none transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 aria-expanded:border-primary-blue-200 aria-expanded:ring aria-expanded:ring-primary-blue-900 dark:bg-quaternary-200 dark:text-white dark:placeholder:text-white dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;
}

.arrow-select-tags {
  @apply pointer-events-none relative -right-1 flex h-4 w-5 items-center justify-center text-gray-700;
}

.error-tags {
  @apply rounded border-1 border-red-500 ring ring-red-100;
}

.select-tags-container {
  @apply relative flex min-h-[36px] w-full cursor-default appearance-none flex-row justify-between overflow-hidden rounded border border-tertiary-500 bg-white text-base leading-8 text-tertiary-100 transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 dark:bg-quaternary-200 dark:text-white dark:placeholder:text-white dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;

  background-color: white !important;
}

.select-tags-input {
  @apply flex min-h-[36px] w-full appearance-none flex-row justify-between rounded border border-none border-tertiary-500 bg-white pl-3 font-content text-base text-sm font-light leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out placeholder:text-tertiary-400 disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 dark:border-white dark:bg-quaternary-200 dark:text-white dark:ring-0 dark:placeholder:text-tertiary-200 dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;

  background-color: white !important;
}
.input-code-highlight {
  @apply select-none;

  input {
    @apply pl-1 focus:ring-8;

    &:not(:focus)::placeholder {
      color: theme('colors.tertiary.600');
    }

    &:focus {
      caret-color: transparent;
    }

    &:focus::placeholder {
      color: theme('colors.primary.blue.900');
    }
  }
}

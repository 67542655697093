.tolq-button {
  @apply relative my-auto flex h-12 min-w-max cursor-pointer select-none flex-row items-center justify-center gap-2 overflow-hidden rounded border-1 px-6 py-0 text-center font-bold leading-9 tracking-wide text-white hover:opacity-[0.85] active:opacity-90 shadow-sm;

  &.button--primary {
    @apply border-primary-green-100 bg-primary-green-100;

    &:focus-visible {
      @apply outline outline-1 outline-primary-green-300;
    }

    & svg path {
      @apply fill-primary-green-100;
    }
  }

  &.button--secondary {
    @apply border-primary-blue-100 bg-primary-blue-100;

    &:focus-visible {
      @apply outline outline-1 outline-primary-blue-300;
    }

    & svg path {
      @apply fill-primary-blue-100;
    }
  }

  &.button--danger {
    @apply border-secondary-red bg-secondary-red;

    :focus-visible {
      @apply outline outline-1 outline-primary-red-300;
    }

    & svg path {
      @apply fill-secondary-red;
    }
  }

  &.button--neutral {
    @apply border-quaternary-600 bg-quaternary-600;

    :focus-visible {
      @apply outline outline-1 outline-quaternary-1000;
    }

    & svg path {
      @apply fill-quaternary-600;
    }
  }

  &.button--white {
    @apply bg-white active:bg-white text-tertiary-100;
    border-color: theme('colors.tertiary.500');
    border-width: 1px !important;


    :focus-visible {
      @apply outline outline-1 outline-quaternary-1000;
    }

  }

  &.button--icon {
    @apply flex h-full w-fit min-w-0 flex-col rounded-full border-0 bg-transparent p-0 outline-none cursor-pointer;
    @apply ease-in-out;

    :focus-visible {
      @apply outline-none;
    }

    &:hover:not([disabled]),
    &:hover:not(:disabled) {
      @apply opacity-80;
    }

    &.disabled,
    &[disabled] {
      @apply cursor-not-allowed bg-transparent opacity-80;

      & svg path {
        @apply fill-tertiary-400;
      }
    }
  }

  &.button--icon-btn:not(.button--outlined) {
    @apply flex h-full w-fit min-w-0 flex-col rounded-full border-0 bg-white p-0 shadow-sm outline-none;
    @apply ease-in-out hover:opacity-60;

    :focus-visible {
      @apply outline outline-1 outline-tertiary-900;
    }

    &:hover:not([disabled]),
    &:hover:not(:disabled) {
      @apply opacity-80 shadow-custom;
    }

    &.disabled,
    &[disabled] {
      @apply cursor-not-allowed bg-primary-blue-900 opacity-80;

      & svg path {
        @apply fill-tertiary-400;
      }
    }
  }

  &.button--text,
  &.button--text-small {
    @apply border-0 bg-transparent text-secondary-link;

    & svg path {
      @apply fill-secondary-link;
    }
  }

  &.button--disabled {
    @apply cursor-not-allowed border-tertiary-500 bg-tertiary-500 text-tertiary-400;

    &:focus-visible {
      @apply outline outline-1 outline-tertiary-600;
    }

    & svg path {
      @apply fill-tertiary-500;
    }
  }

  &.button--text--disabled {
    @apply cursor-not-allowed border-none bg-transparent text-tertiary-400;

    &:focus-visible {
      @apply outline outline-1 outline-tertiary-600;
    }

    & svg path {
      @apply fill-tertiary-500;
    }
  }

  &.button--contained.button--primary {
    @apply hover:bg-primary-green-100;
  }

  &.button--contained.button--secondary {
    @apply hover:bg-primary-blue-100;
  }

  &.button--contained.button--danger {
    @apply hover:bg-primary-red-100;
  }

  &.button--primary.button--outlined {
    @apply text-primary-green-100;
  }

  &.button--secondary.button--outlined {
    @apply text-primary-blue-100;
  }

  &.button--danger.button--outlined {
    @apply text-primary-red-100;
  }

  &.button--outlined {
    @apply border-solid bg-transparent;
  }

  &.button--contained {
    @apply border-0;
  }

  &.button--small {
    @apply h-8 text-xs;
  }

  &.button--medium {
    @apply h-9 text-sm;
  }

  &.button--large {
    @apply h-11 text-lg;
  }

  &.button--rounded {
    @apply rounded-full;
  }

  &.button--color-icon-white svg path {
    @apply fill-white delay-100;
  }

  &.button--color-icon-neutral svg path {
    @apply fill-quaternary-600 delay-100;
  }

  &.button--color-icon-blue svg path {
    @apply fill-secondary-link delay-100;
  }

  &.button--color-icon-danger svg path {
    @apply fill-secondary-red delay-100;
  }

  &.button--color-icon-black svg path {
    @apply fill-quaternary-1000 delay-100;
  }

  &.button--size-icon-small svg {
    @apply h-4 w-4;
  }

  &.button--size-icon-medium svg {
    @apply h-4 w-5 scale-125;
  }

  &.button--size-icon-large svg {
    @apply h-4 w-6 scale-150;
  }
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.9);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
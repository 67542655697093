.select-primary {
  @apply flex min-h-[36px] w-full appearance-none flex-row justify-between rounded border border-tertiary-500 bg-white px-3 text-base leading-8 text-tertiary-100 outline-none transition-colors duration-200 ease-in-out focus:border-primary-blue-200 focus:ring focus:ring-primary-blue-900 dark:bg-quaternary-200 dark:text-white dark:placeholder:text-white;
  @apply disabled:cursor-not-allowed disabled:bg-tertiary-800 disabled:text-gray-500 disabled:opacity-75 dark:disabled:bg-quaternary-200 dark:disabled:text-tertiary-200;
}

.arrow-select {
  @apply pointer-events-none relative inset-y-2 -right-1 top-[9px] flex h-4 w-5 items-center justify-center text-gray-700;
}

.error-input {
  @apply rounded border-1 border-red-500 ring ring-red-100;
}

.check-selected {
  & svg path {
    // @apply fill-tertiary-100;
  }
}
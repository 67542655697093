.typography {
  @apply my-auto font-content transition-all;
}

.medium7xl-typography {
  @apply text-7xl font-semibold leading-10;
}

.bold7xl-typography {
  @apply text-7xl font-semibold leading-10;
}

.bold6xl-typography {
  @apply text-6xl font-semibold leading-10;
}

.bold5xl-typography {
  @apply text-5xl font-semibold leading-10;
}

.bold4xl-typography {
  @apply text-4xl font-semibold leading-10;
}

.bold1-typography {
  @apply text-3xl font-semibold leading-9;
}

.bold2-typography {
  @apply text-2xl font-semibold leading-9;
}

.bold3-typography {
  @apply text-xl font-semibold leading-9;
}

.bold4-typography {
  @apply text-lg font-semibold leading-8;
}

.bold5-typography {
  @apply text-base font-semibold leading-7;
}

.bold6-typography {
  @apply text-[10px] font-semibold leading-[10px];
}

.medium7xl-typography {
  @apply text-7xl font-medium leading-10;
}

.medium6xl-typography {
  @apply text-6xl font-medium leading-10;
}

.medium5xl-typography {
  @apply text-5xl font-medium leading-10;
}

.medium4xl-typography {
  @apply text-4xl font-medium leading-10;
}

.medium1-typography {
  @apply text-3xl font-medium leading-9;
}

.medium2-typography {
  @apply text-2xl font-medium leading-8;
}

.medium3-typography {
  @apply text-xl font-medium leading-7;
}

.medium4-typography {
  @apply text-lg font-medium leading-6;
}

.medium5-typography {
  @apply text-base font-medium leading-5;
}

.medium6-typography {
  @apply text-[10px] font-medium leading-[10px];
}

.regular7xl-typography {
  @apply text-7xl font-normal leading-10;
}

.regular6xl-typography {
  @apply text-6xl font-normal leading-10;
}

.regular5xl-typography {
  @apply text-5xl font-normal leading-10;
}

.regular4xl-typography {
  @apply text-4xl font-normal leading-10;
}

.regular1-typography {
  @apply text-3xl font-normal leading-9;
}

.regular2-typography {
  @apply text-2xl font-normal leading-8;
}

.regular3-typography {
  @apply text-xl font-normal leading-7;
}

.regular4-typography {
  @apply text-lg font-normal leading-6;
}

.regular5-typography {
  @apply text-base font-normal leading-5;
}

.regular6-typography {
  @apply text-[10px] font-normal leading-[10px];
}

.light7xl-typography {
  @apply text-7xl font-light leading-10;
}

.light6xl-typography {
  @apply text-6xl font-light leading-10;
}

.light5xl-typography {
  @apply text-5xl font-light leading-10;
}

.light4xl-typography {
  @apply text-4xl font-light leading-10;
}

.light1-typography {
  @apply text-3xl font-light leading-6;
}

.light2-typography {
  @apply text-2xl font-light leading-6;
}

.light3-typography {
  @apply text-xl font-light leading-5;
}

.light4-typography {
  @apply text-lg font-light leading-5;
}

.light5-typography {
  @apply text-xs font-light leading-3;
}

.light6-typography {
  @apply text-[10px] font-light leading-[10px];
}

.noGutter {
  @apply m-0;
}

.gutterBottom {
  @apply mb-0;
}

.noWrap {
  @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
}
.tolq-snackbar {
  @apply flex w-full justify-between rounded-5xl px-2 py-3;
}

.snackbar--neutral,
.snackbar--default {
  @apply border border-solid border-tertiary-600 bg-white;
}

.snackbar--info {
  @apply bg-primary-blue-900;

  & path {
    @apply fill-gradient-royal-blue;
  }
}

.snackbar--warning {
  @apply bg-primary-orange-1200;

  & path {
    @apply fill-primary-orange-100;
  }
}

.snackbar--danger,
.snackbar--error {
  @apply bg-primary-red-300;

  & path {
    @apply fill-primary-red-100;
  }
}

.snackbar--success {
  @apply bg-primary-green-300;

  & path {
    @apply fill-primary-green-100;
  }
}